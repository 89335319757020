












































import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { ACTION, actionMap } from '@/utils/gridActions/actionMap';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MInvoice } from '@/models/MInvoice';
import { mixinPDF } from '@/components/mixins/pdf';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'DeliveryShow',

  components: {
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinPDF,
  ],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gridActionList: [
        {
          ...actionMap.get(ACTION.CONTEXT_MENU),
          childActionList: [
            {
              ...actionMap.get(ACTION.PDF),
              label: `${this.$tc('delivery.model', 1)} ${this.$t('common.action.pdf')}`,
            },
          ],
        },
      ],

      gridColumnDefinition: [
        {
          headerName: `${this.$tc('delivery.model', 1)} ${this.$t('delivery.property.number')}`,
          field: 'deliveryNumber',
          filter: true,
          sort: 'desc',
        },
        {
          headerName: this.$tc('customer.model', 1),
          field: 'customerName',
          filter: true,
        },
        {
          headerName: this.$t('delivery.property.date'),
          field: 'date',
          filter: true,
        },
        {
          headerName: `${this.$tc('invoice.model', 1)} ${this.$t('invoice.property.number')}`,
          field: 'invoiceNumber',
          filter: true,
        },
      ],
      deliveryYear: new Date().getFullYear(),
      yearList: [],
    };
  },

  computed: {
    displayedDeliveryYear(): Object {
      return {
        year: this.deliveryYear,
      };
    },

    deliveryList(): Array<MDeliveryBag> {
      return MDeliveryBag.all();
    },

    gridRowData(): Array<any> {
      return this.deliveryList.map(
        delivery => delivery.asListData,
      );
    },
  },

  methods: {
    async loadDeliveryYear(year: any): Promise<any> {
      this.deliveryYear = year.year;

      await MDeliveryBag._fetch();
    },

    async openPdf(Id: string): Promise<any> {
      const deliveryBag = MDeliveryBag.query().withAllRecursive().find(Id);

      this.$refs['hiddenPdf'].src = await this.generateDelivery(deliveryBag);
    },
  },

  async beforeMount(): Promise<any> {
    this.yearList = await MDeliveryBag._getDeliveryYears();
  },
});
